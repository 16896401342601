import { useMemo } from 'react'

import useProducts from './useProducts'


const useProductsByIds = (productIds: string[]) => {
  const { products, isFetching } = useProducts({
    variables: {
      input: {
        slugs: productIds,
        visibility: [ 'LANDING', 'VISIBLE' ],
      },
    },
    skip: !productIds.length,
    ssr: false,
  })

  const filteredProducts = useMemo(() => {
    if (!products) {
      return []
    }

    return productIds.map((productId) => {
      return products.find((product) => {
        const { id } = product

        return productId === id.toString()
      })
    }).filter(Boolean)
  }, [ products, productIds ])

  return {
    isFetching,
    products: filteredProducts,
  }
}

export default useProductsByIds
